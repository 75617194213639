import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import './output.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './pages/Home/Home';
import Digital from './pages/Digital';
import Hr from './pages/Hr';
import Fin from './pages/Fin';
import NotFound from './pages/NotFound';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home></Home>,
  },
  {
    path: "/digital-studio",
    element: <Digital></Digital>,
  },
  {
    path: "/hr-consulting",
    element: <Hr></Hr>,
  },
  {
    path: "/financial-consulting",
    element: <Fin></Fin>,
  },
  {
    path: "/404",
    element: <NotFound></NotFound>,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <RouterProvider router={router} />

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
